*{
  font-family: 'Open Sans', sans-serif;
  line-height: 2.3;
  color: #666666;
  font-size: 18px;
}

/*cesto koristeni css*/
.padding-text{
  padding-right: 250px;
  text-align: left;
}
.img-text-main{
  display: flex;
  justify-content: space-between;
}
.vid-text-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.vid-text{
  padding-bottom: 0;
  padding-top: 0;
}
.last-text{
  padding-bottom: 30px;
}
.title-img-align{
  font-size: 60px !important;
  font-weight: bold;
  text-align: center;
  padding: 10px 10px 10px 10px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Kalam', cursive;
      color: white;
}
.title-of-col{
  font-size: 32px;
  font-weight: bold;
  padding: 50px 0;
  font-family: 'Rufina', serif;
  color: #5DB9FF;
}
.video-a-name{
  font-family: 'Rufina', serif;
}

.header{
    background-color: #5DB9FF;
    background-position: center;
    display: flex;
    justify-content: space-between;
}
.carousel-item p{
    padding: 15px;
    font-size: 55px;
    font-weight: bold;
    letter-spacing: 2px;
    text-decoration: none;
    margin-top: 0;
    margin-bottom: 0;
    color: #ffffff;
}
.carousel-item a:hover{
  text-decoration: none !important;
}
.header-line{
  font-family: 'Dancing Script', cursive;
}
.header-line:hover{
  text-decoration: none !important;
}
.dropdown-item{
    color: #666666 !important;
}
.dropdown-item:hover{
    color: #555555 !important;
}
.carousel-item p span{
    color: #555555;
}
.header img{
    padding: 10px;
    padding-bottom: 10px;
    width: 200px;
}
.navbar{
  padding: 0;
  height: 77px;
}
nav ul{
    list-style: none;
    padding: 10px;
    margin: 0 0;
}
nav li{
    display: inline-block;
}
nav a{
    font-size: 17px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    text-decoration: none;
    text-transform: uppercase;
    color: #666666;
}
nav a:hover{
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    color: #555555;
}
.nav-icon {
    display: none;
}

.footer-big{
  background-color: #5DB9FF;
}
.buttonOpen{
  border: none;
  background-color: transparent;
  outline: none;
}
.buttonOpen:hover {
  outline: none;
  border: none;
}
.buttonOpen:active {
  outline: none;
  border: none;
}
.footer-text-logo{
  display: flex;
  justify-content: space-between;
}
.footer-text-main{
  width: 50%;
  display: flex;
  font-size: 50px;
  padding-left: 30px;
  font-weight: bold;
  align-items: center;
  font-family: 'Kalam', cursive;
  color: white;
}
.footer-img{
  width: 50%;
  display: flex;
  justify-content: center;
}
.footer-logo{
  width: 30%;
  padding-top: 20px;
}
.footer-text-name{
  padding-left: 30px;
  font-size: 32px;
  font-weight: bold;
}
.footer-info{
  padding-top: 10px;
  padding-left: 30px;
}
.footer-address{
  margin: 0 !important;
}
.footer-mail-href{
  color:#666666 !important;
  font-weight: bold !important;
}
.footer-mail-href:hover{
  text-decoration: none;
}
.footer-copy{
  padding-left: 30px;
  padding-bottom: 20px;
  margin-bottom: 0;
}
.card-fix{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card-border{
  padding: 20px;
  display: flex;
  justify-content: center;
}
.cards-header{
  text-align: center;
  background: url("./img/blur.jpg");
  background-position: center;
  background-size: cover;

}
.cards-header-b{
  text-align: center;
  background: url("./img/blurb.jpg");
  background-position: center;
  background-size: cover;

}
.boblur{
  background-image: url('./img/bo.png');
  background-position: center;
  background-size: cover;
}
.card{
  overflow: hidden !important;
  border-radius: 0 !important;
}
.slika-naslovna{
  border: 7px solid white;
  width: 50%;
  cursor: pointer;
}
.sslika{
  height: 500px;
  width: 700px;
}
.card-img-top{
  transition: ease-in-out 0.3s;
  border-radius: 0 !important;
  height: 12rem;
  cursor: pointer;
}
.card-img-top:hover{
  transform: scale(1.1);
}
.card-title{
  font-weight: bold;
  font-family: 'Rufina', serif;
}
.btn-outline-primary{
  color: #5DB9FF;
  font-weight: bold;
  border: 3px solid #5DB9FF;
}
.btn-outline-primary:hover{
  color: white;
  font-weight: bold;
  border: 3px solid #5DB9FF;
  background: #5DB9FF;
}
.big-title{
  padding: 50px 0;
  text-align: left;
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 10px;
  font-family: 'Rufina', serif;
  color: #5DB9FF;
}
.size-last{
  font-size: 40px !important;
}
.haiku{
  font-style: italic;
  padding: 30px 0;
}
.knjige-slika{
  text-align: center;
  padding-bottom: 30px;
}
.blue{
  background: #d3e3ec;
}
.naslovni-gornji-text{
  padding: 50px 30px;
  font-size: 18px !important;
  line-height: 2.0 !important;
  padding-top: 0px;
}
.naslov-naslovna{
  font-size: 40px;
  padding: 0 30px;
  padding-top: 50px;
  font-weight: bold;
  font-style: italic;
  font-family: 'Rufina', serif;
  color: #5DB9FF;
}
.yellow{
  background: #fff5e5;
}
.naslovni-donji-text{
  padding: 50px 30px;
  font-size: 18px !important;
  line-height: 2.0 !important;
}
.div1{
  width: 70%;
}
.div2{
  width: 30%;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.div1 img{
  width: 100%;
  padding: 50px 0px;
}
.vidi-ostalo{
  font-size: 20px;
  font-weight: bold;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 30px;
  cursor: pointer;
}
.like-unlike{
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}
.card-news{
  border: none;
}
.style-cards{
  display: flex;
  padding: 30px 0;
}
#readMore{
  cursor: pointer;
}
.audio-player{
  padding: 50px;
  
}
.rhap_main-controls-button{
  line-height: 0 !important;
}
.rhap_volume-button{
  line-height: 0 !important;
}
.rhap_repeat-button{
  line-height: 0 !important;
}
.background-bosmans{
  background: #5DB9FF;
  box-shadow: 10px 10px 78px -10px rgba(255,255,255,0.39);
}
.bosblur{
  background-image: url('./img/bosblur.jpg');
  background-position: center;
  background-size: cover;
}
.pot-num{
  padding-bottom: 20px;
  text-align: left;
}
.pot-img{
  width: 1100px;
  box-shadow: 4px 7px 26px -4px rgba(0,0,0,0.7);
}
.booms{
  text-align: center;
}
.bib-slika{
  width: 600px;
}
.alignleft {
  float: left;
  margin: 0 15px 15px 0;
  width: 500px;
}

.footer-text-name{
  font-family: 'Rufina', serif;
}
.logo-head{
  width: 20%;
}
.slidess{
  width: 80%;
  text-align: center;
}
.ptts{
  padding-top: 50px;
}
.top-move{
  padding-top: 60px;
}
.w-100{
  border: none;
}
.flink{
  margin: 0 !important;
}





@media screen and (max-width: 768px) {
    .carousel-item p{
        font-size: 35px;
    }
    .header img{
        width: 200px;
    }
    .navbar{
      height: 120px;
    }
    .bos-slika{
      width: 500px !important;
    }
    .title-img-align{
      font-size: 40px !important;
    }
    .padding-text{
      padding: 0;
    }
    .bos-slika2{
      width: 200px !important;
    }
    .video-multi{
      width: 560px;
      height: 340px;
    }
    .pot-img{
      width: 600px;
    }
    .big-title{
      font-size: 30px;
    }
    .pot-num{
      font-size: 22px !important;
    }
    .booms {
      display: flex;
      flex-direction: column;
    }
    .sslika{
      height: 400px;
      width: 600px;
    }
    .header{
      justify-content: center;
    }
    .slidess{
      width: 60%;
    }
    .logo-head{
      width: 20%;
    }
    .carousel-item p{
      font-size: 26px;
    }
    .ptts{
      padding-top: 50px;
    }
}
@media screen and (max-width: 425px) {
  .carousel-item{
      padding-top: 15px ;
  }
  .carousel-item p{
      padding-top: 25px !important;
      padding: 5px;
      letter-spacing: 0;
  }
  #last{
    padding-top: 0px !important;
  }
  .header img{
      width: 120px;
  }
  .navbar{
    height: 50px;
  }
  .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #555555;
      position: absolute;
      top: 50px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
    .nav-link{
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 0;
      padding-top: 0;
    }
    .all-list{
      padding-bottom: 8px;
      padding-top: 8px;
    }
    .nav-menu.active {
      background: whitesmoke;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: #666666;
      border: none;
    }
    .nav-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 17px;
      cursor: pointer;
      color: #666666;
    }
    .footer-text-logo{
      display: block;
    }
    .footer-text-main{
      width: 100%;
      text-align: center;
      padding-left: 0px;
      font-size: 35px;
      justify-content: center;
     
    }
    .footer-img{
      width: 100%;
    }
    .footer-rest{
      text-align: center;
    }
    .footer-text-name{
      font-family: 'Rufina', serif;
    }
    .footer-text-name,
    .footer-info,
    .footer-address,
    .footer-mail,
    .footer-copy{
      padding-left: 0px;
    
    }
    .footer-copy{
      padding-left: 0px;
      font-size: 10px;
    }
    .footer-copy b{
      font-size: 10px;
    }
    .style-cards{
      display: block;
    }
    .main-div{
      padding-top: 15px;
    }
    .bos-slika{
      width: 270px !important;
    }
    .title-img-align{
      font-size: 20px !important;
    }
    .carousel-item{
      padding-top: 0;
    }
    .video-multi{
      width: 360px;
      height: 240px;

    }
    .wp-video-shortcode{
      width: 360px !important;
      height: 240px !important;
      
    }
    .wp-video{
      display: flex;
      justify-content: block;
    }
    .pot-img{
      width: 380px;
    }
    .big-title{
      font-size: 26px;
    }
    .pot-num{
      font-size: 18px !important;
    }
    .alignleft {
      float: center;
      margin: 0 0 15px 0;
    }
    .sslika{
      height: 200px;
      width: 300px;
    }
    .slidess{
      width: 60%;
    }
    .logo-head{
      width: 20%;
    }
    .header{
      justify-content: center;
    }
    .carousel-item p{
      font-size: 19px;
    }
    .ptts{
      padding-top: 50px;
    }
}
.wp-video-shortcode{
  width: 500px !important;
  height: 300px !important;
  
}
.wp-video{
  display: flex;
  justify-content: center;
}
.video-a-name{
  text-align: center;
}
.big-title-upper{
  text-transform: uppercase;
}